.markdown > * {
    all: revert;
}

.markdown > h1 {
    font-size: 24px;
}

.markdown > h2 {
    font-size: 20px;
}

.markdown > h2 {
    font-size: 20px;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5em;
}

.assistant-message {
    align-items: start;
}

.system-message {
    align-items: center;
}

.user-message {
    align-items: end;
}

.system-message > div {
    background-color: #f3f4f6;
}

.user-message > div {
    background-color: #2563eb;
    color: #ffffff;
}

.assistant-message > div {
    background-color: #ffffff;
}